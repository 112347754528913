
(function () {
    if (!document.getElementById("Code"))
        return;

    var moveToNext = function (e) {
        var target = e.srcElement || e.target;
        var maxLength = parseInt(target.attributes["maxlength"].value, 10);
        var myLength = target.value.length;
        if (myLength >= maxLength && e.keyCode !== 8) {
            var next = target;
            while (next = next.nextElementSibling) {
                if (next == null)
                    break;
                if (next.tagName.toLowerCase() === "input") {
                    next.focus();
                    break;
                }
            }
        }
        // Move to previous field if empty (user pressed backspace)
        else if (myLength === 0 && e.keyCode === 8) {
            var previous = target;
            while (previous = previous.previousElementSibling) {
                if (previous == null)
                    break;
                if (previous.tagName.toLowerCase() === "input") {
                    previous.focus();
                    break;
                }
            }
        }
    }

    var copyInputToHiddenField = function () {
        var code = "";
        document.querySelectorAll('.input-otp').forEach(function (field) {
            code += field.value;
        });
        document.getElementById("Code").setAttribute('value', code);
    }

    var container = document.getElementsByClassName("otp")[0];
    container.addEventListener('keydown', moveToNext);
    container.addEventListener('keyup', copyInputToHiddenField);

})();